#cards {
  height: 100%;
  // width: 18rem;
  text-align: center;
  z-index: 10000;
  > .card {
    border: none;
    height: 77%;
    box-shadow: 0px 8px 10px 1px #f1f1f1;
    border-radius: 15px;
    img {
      object-fit: contain;
    }
  }
  .sub-title {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // text-overflow: ellipsis;
    // word-break: break-all;
    margin-top: 10px;
    text-align: start;
  }

  .readMore-button {
    text-align: start;
  }
}
.modal-header .btn-close {
  &:lang(ar) {
    position: absolute !important;
    left: 0 !important;
  }
}
