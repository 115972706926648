#Footer{
    background-color: #018b89; 
    max-width: 100%;
    padding: 15px 0;
    .title{
        text-align: center;
        color: white;
        font-size: 20px;

        @media only screen and (max-width: 767px) {
            font-size: 18px;
        }
        
        @media only screen and (max-width: 425px) {
            font-size: 15px;
        }
    }
}