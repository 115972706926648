@import "assets/styles/variables";
#root {
  scroll-behavior: smooth !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  &:lang(ar) {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem) !important;
    background-position: left calc(0.375em + 0.1875rem) center;
  }
}

#home-page {
  h4 {
    text-overflow: ellipsis;
    overflow: hidden;
    // height: 140px;

    @media only screen and (max-width: 575px) {
      // height: 130px;
    }
  }

  .time {
    border: 1px solid black;
    width: 65%;
    height: 90%;
    border-radius: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 1399px) {
      height: 75%;
      width: 70%;
    }

    @media only screen and (max-width: 1199px) {
      height: 85%;
      width: 90%;
    }

    @media only screen and (max-width: 991px) {
      height: 80%;
      width: 110%;
    }

    @media only screen and (max-width: 767px) {
      height: 90%;
      width: 75%;
    }
    @media only screen and (max-width: 575px) {
      height: 95%;
      width: 95%;
    }

    @media only screen and (max-width: 425px) {
      height: 88%;
      width: 90%;
    }

    @media only screen and (max-width: 375px) {
      height: 85%;
      width: 100%;
    }

    @media only screen and (max-width: 320px) {
      height: 75%;
      width: 110%;
    }
  }

  .title {
    text-shadow: 0px -30px rgba(0, 0, 0, 0.05);
  }

  .clock {
    text-align: -webkit-center;
  }


  .slider-content {
    text-align: -webkit-center;
    z-index: 1;
  }

  .card-img {
    z-index: 1000;
    border: none;
    height: 78%;
    box-shadow: 0px 8px 10px 1px #f1f1f1;
    border-radius: 15px;
    padding-bottom: 10px;

    @media only screen and (max-width: 991px) {
      height: 70% !important;
    }

    @media only screen and (max-width: 767px) {
      display: block;
    }
  }

  .image {
    @media only screen and (max-width: 1399px) {
      width: 100%;
    }

    @media only screen and (max-width: 1199px) {
      width: 100%;
      margin: 0;
    }

    @media only screen and (max-width: 991px) {
      width: 100%;
      height: 290px !important;
    }

    @media only screen and (max-width: 767px) {
      height: 100% !important;
    }

    @media only screen and (max-width: 425px) {
    }
  }

  .screens {
    background-color: #01b5b2;
    border-radius: 75px;
    position: relative;
    @media only screen and (max-width: 575px) {
      background-size: cover;
    }
  }

  .title-screen {
    margin-top: 4%;

    h1 {
      color: white;
      &::before {
        content: attr(data-title);
        display: flex;
        color: rgb(255, 255, 255);
        opacity: 0.09;
        position: absolute;
        top: 3%;
        left: 30%;
        font-size: 55px;
      }
    }
    @media only screen and (max-width: 1440px) {
      h1 {
        &::before {
          top: 17%;
          left: 28%;
          font-size: 55px;
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      h1 {
        &::before {
          top: 16%;
          left: 22%;
          font-size: 45px;
        }
      }
    }

    @media only screen and (max-width: 991px) {
      margin-top: 25%;
      h1 {
        &::before {
          top: 17%;
          left: 20%;
          font-size: 45px;
        }
      }
    }

    @media only screen and (max-width: 767px) {
      h1 {
        &::before {
          top: 16%;
          left: 16%;
          font-size: 40px;
        }
      }
    }

    @media only screen and (max-width: 575px) {
      margin-top: 33%;

      h1 {
        &::before {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 425px) {
      margin-top: 35%;
      text-shadow: 0 0;
      h1 {
        font-size: 22px;
      }
    }
    @media only screen and (max-width: 375px) {
      margin-top: 37%;
      text-shadow: 0 0;
      h1 {
        font-size: 18px;
      }
    }
  }

  .swiper {
    user-select: none;
    width: 100%;
    height: 115%;
    overflow: visible;
    padding: 1% 0;

    @media only screen and (max-width: 575px) {
      width: 50%;
      padding: 8% 0;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: none;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .swiper-button-prev {
    color: black;
    top: 20px;
    background-image: url(../../../public/Images/arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    transform: scale(-1, 1);
    &::after {
      display: none;
    }
    @media only screen and (max-width: 1199px) {
      top: 10px;
    }

    @media only screen and (max-width: 991px) {
      top: -10px;
      left: -10px;
    }
    @media only screen and (max-width: 575px) {
      top: -15px;
      left: -35px;
    }
    @media only screen and (max-width: 425px) {
      top: -15px;
      left: -25px;
    }
  }
  .swiper-button-next {
    color: black;
    top: 20px;
    background-image: url(../../../public/Images/arrow.png);
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;

    &::after {
      display: none;
    }

    @media only screen and (max-width: 1199px) {
      top: 10px;
    }

    @media only screen and (max-width: 991px) {
      top: -10px;
      right: -10px;
    }
    @media only screen and (max-width: 575px) {
      top: -15px;
      right: -35px;
    }
    @media only screen and (max-width: 425px) {
      top: -15px;
      right: -25px;
    }
  }

  // download app

  .download {
    // background-image: url(../../../public/Images/download2.png);
    height: 550px;
    max-width: 100%;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    position: relative;
    display: flex;
    align-items: center;
    background-color: theme-color('gray4');
    margin: 100px 0;
    border-radius: 100px 0 100px 0;

    h1 {
      &::before {
        content: attr(data-title);
        display: flex;
        color: #000;
        opacity: 0.03;
        position: absolute;
        top: -50%;
        left: 35%;
        font-size: 48px;
      }
    }

    @media only screen and (max-width: 1399px) {
      height: 550px;
      h1 {
        &::before {
          top: -50%;
          left: 34%;
        }
      }
    }
    @media only screen and (max-width: 1199px) {
      height: 550px;
      h1 {
        &::before {
          top: -50%;
          left: 31%;
        }
      }
    }
    @media only screen and (max-width: 991px) {
      height: 475px;
      h1 {
        &::before {
          display: none;
        }
      }
    }
    @media only screen and (max-width: 767px) {
      height: 450px;
    }
    @media only screen and (max-width: 576px) {
      height: 850px;
    }
    @media only screen and (max-width: 375px) {
      height: 750px;
    }
    // @media only screen and (max-width: 425px) {
    //   height: 350px;
    // }

    .download-app-card {
      img {
        width: 32px;
      }
    }

    .download-content {
      // margin-top: 10px;

      a {
        :hover {
          color: rgb(66, 66, 66);
        }
      }

      @media only screen and (max-width: 1399px) {
        // margin-top: 130px;
      }
      @media only screen and (max-width: 1199px) {
        // margin-top: 40px;
      }
    }

    .image {
      display: inline-table;
      align-items: center;
      height: auto;
      width: 100%;
      z-index: 4;
      @media only screen and (max-width: 1199px) {
      }

      @media only screen and (max-width: 991px) {
      }

      @media only screen and (max-width: 767px) {
        display: none;
      }

      @media only screen and (max-width: 425px) {
        display: none;
      }

      @media only screen and (max-width: 375px) {
        display: none;
      }

      @media only screen and (max-width: 320px) {
        display: none;
      }
    }

    // .download-details {
    //   .qr-div {
    //     @media only screen and (max-width: 425px) {
    //       &:lang(ar) {
    //         text-align: center;
    //       }
    //       &:lang(en) {
    //         text-align: center;
    //       }
    //     }
    //   }
    //   // margin-top: 100px;

    //   @media only screen and (max-width: 1399px) {
    //     h2 {
    //       font-size: 28px;
    //     }
    //   }

    //   @media only screen and (max-width: 1199px) {
    //     // margin-top: 120px;
    //     h2 {
    //       font-size: 23px;
    //     }
    //     h4 {
    //       font-size: 18px;
    //     }
    //   }

    //   @media only screen and (max-width: 991px) {
    //     h2 {
    //       font-size: 17px;
    //     }
    //     h1 {
    //       font-size: 30px;
    //     }
    //     .qrimg {
    //       width: 50%;
    //     }
    //     h4 {
    //       font-size: 14px;
    //     }
    //     h6 {
    //       font-size: 14px;
    //     }
    //   }

    //   @media only screen and (max-width: 767px) {
    //     // margin-top: 140px;
    //     h2 {
    //       font-size: 25px;
    //     }
    //     h1 {
    //       font-size: 40px;
    //     }
    //     .qrimg {
    //       width: 30%;
    //       justify-content: center;
    //     }
    //     h4 {
    //       font-size: 20px;
    //     }
    //     h6 {
    //       font-size: 16px;
    //     }
    //   }

    //   @media only screen and (max-width: 425px) {
    //     // margin-top: 35px;
    //     h2 {
    //       font-size: 20px;
    //     }
    //     h1 {
    //       font-size: 30px;
    //     }
    //     .qrimg {
    //       width: 30%;
    //       justify-content: center;
    //     }
    //     h4 {
    //       font-size: 20px;
    //       display: contents;
    //     }
    //     h6 {
    //       font-size: 16px;
    //     }
    //   }

    //   @media only screen and (max-width: 375px) {
    //     // margin-top: 35px;
    //     h2 {
    //       font-size: 20px;
    //     }
    //     h1 {
    //       font-size: 30px;
    //     }
    //     .qrimg {
    //       width: 30%;
    //       justify-content: center;
    //     }
    //     h4 {
    //       font-size: 16px;
    //       display: contents;
    //     }
    //     h6 {
    //       font-size: 11px;
    //     }
    //   }

    //   @media only screen and (max-width: 320px) {
    //   }

    //   @media only screen and (min-width: 992px) {
    //     .qrimg {
    //       width: 100%;
    //     }
    //   }
    // }

    .qrimg {
      width: 30%;
      @media only screen and (max-width: 1399px) {
        width: 35%;
      }
      @media only screen and (max-width: 1199px) {
        width: 40%;
      }
      @media only screen and (max-width: 991px) {
        width: 45%;
      }
      @media only screen and (max-width: 767px) {
        width: 60%;
      }
      @media only screen and (max-width: 576px) {
        width: 40%;
      }
      @media only screen and (max-width: 425px) {
        width: 50%;
      }
    }

    // ------

    .header-download-btns-container {
      // third item not in flex
      gap: 20px;
      flex-direction: column;
      align-items: center;

      :nth-child(3n) {
        width: 100%;
        height: 0;
      }

      :nth-child(4n) {
        margin-top: -20px !important;
      }
    }

    .header-download-btn {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      border-radius: 15px;
      color: #000;
      font-size: 14px;
      background-color: #fff;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      padding: 8px 20px;
      width: 190px;

      &-subtitle {
        font-size: 12px;
      }

      &-title {
        font-size: 18px;
      }

      img {
        width: 25px;
      }
    }

    .header-download-btn:hover {
      filter: brightness(0.9);
    }
    .header-download-btn:active {
      filter: brightness(0.8);
    }
  }

  // news

  .news {
    background-color: #00a19d;
    border-radius: 75px 75px 0 0;

    .footer-logo {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      img {
        width: 400px;
        height: 125px;
        margin: 30px 0;

        @media only screen and (max-width: 1399px) {
          width: 275px;
          height: auto;
        }
        @media only screen and (max-width: 1199px) {
          width: 250px;
          height: auto;
        }
        @media only screen and (max-width: 991px) {
          width: 200px;
          height: auto;
        }
        @media only screen and (max-width: 767px) {
          width: 200px;
          height: auto;
        }
        @media only screen and (max-width: 425px) {
          width: 150px;
          height: auto;
        }
      }
    }

    .contact {
      display: flex;
      justify-content: center;
      // margin-top: 80px;
      margin-bottom: 20px;
      flex-wrap: wrap;
      gap: 50px;

      h4 {
        display: contents;
        color: white;
        font-size: 20px;
        font-weight: 500;
      }

      .icons {
        color: theme-color("white");
        font-size: 25px;
        margin-right: 5px;
      }

      @media only screen and (max-width: 1199px) {
        h4 {
          font-size: 18px;
        }
        .icons {
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 991px) {
        h4 {
          font-size: 14px;
        }
        .icons {
          margin-right: 5px;
          font-size: 17px;
        }
      }

      @media only screen and (max-width: 767px) {
        gap: 20px;
        h4 {
          font-size: 15px;
        }
        .icons {
          margin-right: 8px;
          font-size: 20px;
        }
      }

      @media only screen and (max-width: 425px) {
        h4 {
          font-size: 14px;
        }
        .icons {
          margin-right: 5px;
          font-size: 20px;
        }
      }
    }
  }
}
