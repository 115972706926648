@font-face {
    font-family: "Bahij_TheSansArabic";
    font-weight: 100;
    src: local("GothicA1"),
      url("../../fonts/Bahij_TheSansArabic/Bahij_TheSansArabic-Light.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: "Bahij_TheSansArabic";
    font-weight: 300;
    src: local("GothicA1"),
      url("../../fonts/Bahij_TheSansArabic/Bahij_TheSansArabic-ExtraLight.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: "Bahij_TheSansArabic";
    font-weight: 500;
    src: local("GothicA1"),
      url("../../fonts/Bahij_TheSansArabic/Bahij_TheSansArabic-Plain.ttf")
        format("truetype");
  }
  
  @font-face {
    font-family: "Bahij_TheSansArabic";
    font-weight: 700;
    src: local("GothicA1"),
      url("../../fonts/Bahij_TheSansArabic/Bahij_TheSansArabic-SemiBold.ttf")
        format("truetype");
  }
  @font-face {
    font-family: "Bahij_TheSansArabic";
    font-weight: 900;
    src: local("GothicA1"),
      url("../../fonts/Bahij_TheSansArabic/Bahij_TheSansArabic-Bold.ttf")
        format("truetype");
  }
  
  * {
    font-family: "Bahij_TheSansArabic";
    // font-weight: 500 !important;
  }
  
  .fw-bolder {
    font-weight: 900;
  }
  .fw-bold {
    font-weight: 700;
  }
  .fw-normal {
    font-weight: 500;
  }
  .fw-light {
    font-weight: 300;
  }
  .fw-lighter {
    font-weight: 100;
  }
  
  html,
  body,
  #root {
    overflow-x: hidden;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    position: static;
    min-height: 100vh;
    width: 100%;
  }
  
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  span,
  input,
  button,
  select,
  optgroup,
  textarea {
    line-height: unset !important;
    margin-bottom: 0;
  }
  
  
  
  .w-fit {
    max-width: fit-content !important;
  }
  